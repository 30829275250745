.Login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__Card {
       border-radius: 35px;
       box-shadow: 66px 704px 198px 0px rgba(142, 142, 142, 0.00), 42px 451px 181px 0px rgba(196, 196, 196, 0.01), 24px 253px 153px 0px rgba(196, 196, 196, 0.05), 11px 113px 113px 0px rgba(196, 196, 196, 0.09), 3px 28px 62px 0px rgba(196, 196, 196, 0.10);
       border: 1px solid lightgray;
       display: flex;
       height: fit-content;

        &__LeftSection {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-block: 50px;
            &__FirstRow {
                margin-inline: 50px;
            }
            &__SecondRow {
                margin-inline: 50px;
                display: flex;
                flex-direction: column;
                gap: 35px;

                &__InputFields {
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                }

                &__Actions {
                    display: flex;
                    justify-content: space-between;

                    &__ForgotPwd {
                        opacity: 0.5;
                        cursor: pointer;
                    }
                } 
            }
            &__ThirdRow {
                margin-inline: 50px;
                
                &__GetStarted {
                    background: linear-gradient(274deg, #6A57E3, #C26DEA);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }

        &__RightSection {
            flex: 1;
            background: linear-gradient(274deg, #6A57E3, #C26DEA);
            position: relative;
            border-radius: 0px 10px 10px 0 ;
            
            &__Image {
                position: absolute;
                height: 570px;
                width: 500px;
                right: 0;
                bottom: -13.455px;
            }
        }
    }

}