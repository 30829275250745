.JobPost {
    display: flex;
    padding: 20px;
    border-radius: 12px;
    background: linear-gradient(274deg, rgba(106, 87, 227, 0.10) 0%, rgba(194, 109, 234, 0.10) 100%);
    gap: 15px;
    cursor: pointer;

    &__Text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__Title {
            font-size: 24px;
            font-weight: 500;
        }

        &__SubTitle {
            font-size: 18px;
            font-weight: 500;
            opacity: 0.7;
        }
    }
}