.Registration {
    &__Card {
        &__Body {
            &__MainContent {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 35px;

                &__SubHeader {
                    font-weight: 700;
                }

                &__Body {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                    // max-height: 350px;
                    // overflow-y: scroll;
                    // padding-right: 32px;
                }

                &__Footer {
                    display: flex;
                    justify-content: space-between;
                }
            }

            &__Footer {
                height: 22px;
                &__GotoDashboard {
                    background: linear-gradient(274deg, #6A57E3, #C26DEA);;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }
}

.QuestionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 32px;
}