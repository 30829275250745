.Registration {
    &__Card {
        &__Body {
            &__MainContent {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 35px;

                &__SubHeader {
                    font-weight: 700;
                }

                &__Body {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 20px;
              
                    &__Option {
                      background: #f7fafc;
                      padding: 32px;
                      border-radius: 18px;
                      cursor: pointer;
                      transition: background 0.3s;
                      display: flex;
                      align-items: center;
                      flex-direction: row;
                      flex-wrap: wrap;

                      &__Selected {
                        background: #edf2f7 !important;
                        border: 2px solid #5a67d8 !important;
                      }
              
                      input {
                        display: none;
                      }
              
                      &__CustomRadio {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        border: 2px solid #5a67d8;
                        border-radius: 50%;
                        margin-right: 8px;
                        position: relative;
              
                        &::after {
                          content: '';
                          display: block;
                          width: 12px;
                          height: 12px;
                          background: #5a67d8;
                          border-radius: 50%;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          opacity: 0;
                          transition: opacity 0.2s;
                        }
                      }
              
                      input:checked + .custom-radio::after {
                        opacity: 1;
                      }
              
                      &__Description {
                        display: block;
                        color: #4a5568;
                        margin-top: 10px;
                      }
                    }
                }

                &__Footer {
                    display: flex;
                    justify-content: space-between;
                }
            }

            &__Footer {
                height: 22px;
                &__GotoDashboard {
                    background: linear-gradient(274deg, #6A57E3, #C26DEA);;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }
}