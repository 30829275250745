.Landing {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 85px;

    &__Navigation {
        width: 100%;
        background-color: white;
        height: fit-content;

        &__Navbar {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            &__Options {
                display: flex;
                gap: 57px;

                &__Item {
                    color: #000;

                    &:hover {
                        text-decoration: underline;
                        color: #000;
                    }
                }
            }

            &__Actions {
                display: flex;
                gap: 10px;
            }
        }

        &__SubNavbar {
            display: flex;
            width: 100%;
            height: 49px;
            padding: 15px 100px;
            justify-content: space-around;
            align-items: flex-start;
            background: linear-gradient(90deg, #6A57E3 0%, #BA77E3 48%, #331FB0 100%);
            color: white;

            &__Item {
                color: inherit;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        &__Content {
            width: 100%;
    
            &__Carousel {
                // height: 580px !important;
    
                &__Item {
                    height: 100%;
                    width: 100%;
                    position: relative !important;

                    &__OverlayText {
                        position: absolute;
                        top: 200px;
                        left: 88px;
                        display: flex;
                        flex-direction: column;
                        gap: 18px;

                        &__Title {
                            width: 500px;
                            color: #FFF;
                            font-size: 94px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 81px; /* 86.17% */
                        }

                        &__SubTitle {
                            width: 440px;
                            color: #FFF;
                            font-size: 21.188px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 29px; /* 136.873% */
                        }
                    }
                }
            }
        }

    }

    &__Categories {
        display: flex;
        flex-direction: column;
        gap: 100px;
        &__TopCategories {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            &__Title {
                color: #14171F;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 58.86px;
            }
    
            &__List {
                width: 70%;
                // display: flex;
                // align-items: flex-start;
                // gap: 19.036px;

                &__Slider {
                    .slick-prev:before, .slick-next:before {
                        color: black;
                    }

                    &__Item {
                        display: flex;
                        flex-direction: column;
                        gap: 13.336px;
                        img {
                            width: 195px;
                            height: 221.013px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        &__Banner {
            display: flex;
            height: 544px;
            padding: 0px 100px;
            justify-content: center;
            align-items: center;
            gap: 50px;
            background: linear-gradient(274deg, rgba(106, 87, 227, 0.10) 0%, rgba(194, 109, 234, 0.10) 100%);
    
            &__LeftSection {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 10px;
    
                &__Text1 {
                    color: #14171F;
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 58.86px; /* 122.625% */
    
                    span {
                        font-weight: 500;
                    }
                }
    
                &__Text2 {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
    
            &__RightSection {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 80%;
                    width: 80%;
                }
            }
        }
    }


    &__FreelancerBanner {
        height: 575px;
        background: #331FB0;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        position: relative;

        &__LeftSection {
            border-radius: 15px;
            width: 400px;
            height: 400px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__RightSection {
            width: 565px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            &__Title {
                color: #FFF;
                font-size: 42px;
                font-weight: 500;
                line-height: 58.86px; /* 107.018% */
            }

            &__Description {
                color: #FFF;
                font-size: 24px;
                font-weight: 400;
            }

            &__Signup {
                width: 30%;
            }
        }

        &__Rocket {
            position: absolute;
            bottom: 0;
            right: 0;
            
            img {
                height: 450px;
                width: 450px;
            }
        }
    }

    &__Footer {
        display: flex;
        width: 100%;
        padding: 56.544px 113.088px;
        justify-content: space-between;
        border-top: 2.262px solid rgba(255, 255, 255, 0.25);
        background-image: linear-gradient(0deg, rgba(58, 40, 174, 0.60), rgba(58, 40, 174, 0.60)), url('../../assets/footer_bg.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;

        &__LeftSection {
            // padding: 10px;
            width: 475px;
            &__Header {
                display: flex;
                flex-direction: column;
                gap: 7.539px;

                &__Title {
                    color: #FFF;
                    font-size: 24.125px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 115%; /* 27.744px */
                    letter-spacing: -0.965px;
                }

                &__Description {
                    color: #FFF;
                    font-size: 16.586px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        &__RightSection {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 103.287px;
            &__Links {
                display: flex;
                gap: 75.392px;

                &__Quick {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 7.539px;

                    &__Title {
                        color: #FFF;
                        font-size: 19.602px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 115%; /* 22.542px */
                        letter-spacing: -0.784px;
                    }

                    &__Option {
                        color: #FFF;
                        font-size: 16.586px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-decoration: none;
                    }
                }

                &__Social {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 7.539px;

                    &__Title {
                        color: #FFF;
                        font-size: 19.602px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 115%; /* 22.542px */
                        letter-spacing: -0.784px;
                    }

                    &__Option {
                        color: #FFF;
                        font-size: 16.586px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}