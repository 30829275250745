.Input__Container {
    width: 100%;
    &__Label {
        margin-bottom: 15px;
    }

    &__InputBox {
        border-radius: 8px;

        &:hover {
            background-image: linear-gradient(white, white), linear-gradient(274deg, #6A57E3, #C26DEA);
            background-origin: border-box;
            background-clip: padding-box, border-box;
        }

        &:focus {
            border-color: #C26DEA;
            background-origin: border-box;
            background-clip: padding-box, border-box;
        }
    }
}