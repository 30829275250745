.PaymentDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    &__PaymentMethods {
        display: flex;
        gap: 20px;

        img {
            cursor: pointer;
        }
    }

    &__AddCard {
        width: 140px;
    }
}