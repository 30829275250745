.options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;

    .option {
      background: #f7fafc;
      padding: 32px;
      border-radius: 18px;
      cursor: pointer;
      transition: background 0.3s;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;


      &.selected {
        background: #fff;
        border: 2px solid #5a67d8;
      }

      input {
        display: none;
      }

      .custom-radio {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 2px solid #5a67d8;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background: #5a67d8;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: opacity 0.2s;
        }
      }

      input:checked + .custom-radio::after {
        opacity: 1;
      }

      .description {
        display: block;
        color: #4a5568;
        margin-top: 10px;
      }
    }
  }