.ProjectCardContainer {
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #F1F1F1;
    cursor: pointer;

    &__Selected {
        outline: 2px solid #000;
    }

    &__Image {
        border-radius: 7px;
        width: 250px;
        height: 100px;
    }
}