.OfferedServices {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 32px;

    &__SecondRow {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__ThirdRow {
        &__Inputs {
            display: flex;
            gap: 15px;

            &__OpeningHrs {
                flex: 1;
            }

            &__ClosingHrs{
                flex: 1;
            }

        }
    }

    &__FourthRow {
        &__Inputs {
            display: flex;
            gap: 15px;
            
        }
    }
}


::-webkit-scrollbar {
    width: 10px;
    background: rgba(0, 0, 0, 0.06);
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(274deg, #6A57E3, #C26DEA);
    border-radius: 100px;
}
