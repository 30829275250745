.Services {
    display: flex;
    gap: 15px;
    row-gap: 15px;
    flex-wrap: wrap;

    &__Tile {
        // border: 1px solid black;
        height: 40px;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #FAFAFA;

        &__Selected {
            border: 1px solid #C26DEA;
        }
    }
}