.Question__Container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__OptionsList {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__RangeInputs {
        width: 100%;
        display: flex;
        gap: 10px;
    }

}