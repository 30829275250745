.UploadContainer {

    &__Title {
        margin-bottom: 20px;
    }

    // &__InputUploader {
    //     border: 2px dashed #6A57E3;
    //     border-radius: 8px;
    //     padding: 14px;
    //     cursor: pointer;
    //     width: 400px;
    // }

    &__Upload {
        &__Label {
            margin-bottom: 15px;
        }

        &__Input {

            .ant-upload {
                padding: 14px;
            }
        }
    }
}
  
  