.ProfileCard {
    display: flex;
    width: 100%;
    padding: 25px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    border-radius: 20px;
    border: 1px solid #C8C8C8;
    background: #FFF;
    box-shadow: 66px 704px 198px 0px rgba(142, 142, 142, 0.00), 42px 451px 181px 0px rgba(196, 196, 196, 0.01), 24px 253px 153px 0px rgba(196, 196, 196, 0.05), 11px 113px 113px 0px rgba(196, 196, 196, 0.09), 3px 28px 62px 0px rgba(196, 196, 196, 0.10);

    &__UserImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;

        &__Image {
            width: 177.134px;
            height: 177.134px;
            border-radius: 50%;
            object-fit: cover;
        }
        
        &__VerifiedButton {
            margin-top: -9px;
            width: 123px;
        }

        &__EditProfile {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    &__UserName {
        font-weight: 700;
        font-size: 40px;
        color: #000;
    }

    &__About {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-self: stretch;
        
        &__Title {
            font-weight: 700;
            opacity: 0.5;
            color: #000;
            font-size: 20px;
            text-transform: uppercase;
        }

        &__Info {
            div {
                display: flex;
                gap: 10px;
                align-items: center;
                font-weight: 500;
                font-size: 24px;
            }

            &__Divider {
                background: #E8E8E8;
            }
        }
    }

    &__GetInTouch {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__RespondTime {
            opacity: 0.7;
            letter-spacing: -0.88px;
        }
    }

    &__FreelancerAbout {
        &__Description {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &__Title {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: -0.8px;
                opacity: 0.5;
            }

            &__Body {
                letter-spacing: -0.4px;
            }

            &__Location {
                display: flex;
                align-items: center;
                letter-spacing: -0.4px;
            }
        }

        &__Divider {
            background: #E8E8E8;
        }

        &__SkillSet {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &__Title {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: -0.8px;
                opacity: 0.5;
            }

            &__Body {
                display: flex;
                gap: 10px;
                align-self: stretch;
                flex-wrap: wrap;

                &__SkillTile {
                    padding: 10px 15px;
                    border-radius: 60px;
                    background: #FAFAFA;
                }
            }
        }

        &__Links {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &__Title {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: -0.8px;
                opacity: 0.5;
            }

            &__Body {
                display: flex;
                gap: 10px;
            }
        }

        &__Milestones {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &__Title {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: -0.8px;
                opacity: 0.5;
            }

            &__Body {
                display: flex;
                gap: 10px;
                align-self: stretch;

                &__Tile {
                    padding: 10px;
                    border-radius: 10px;
                    background: #F0F0F0;
                }
            }
        }
    }
}