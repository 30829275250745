.DashboardLayout {
    display: flex;
    

    &__Sidebar {
        width: 25%;
    }

    &__MainSection {
        flex: 1
    }
}