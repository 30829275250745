.AccountDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    width: 100%;

    &__FirstRow {
        width: inherit;
        display: flex;
        gap: 25px;
    }

    &__SecondRow {
        width: inherit;
        display: flex;
        gap: 25px;

        &__DOB {
            width: 100%;

            &__DatePicker {
                width: 100%;
            }
        }
    }


}