.Otp__Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 35px;

    input {
        &:hover {
            background-image: linear-gradient(white, white), linear-gradient(274deg, #6A57E3, #C26DEA);
            background-origin: border-box;
            background-clip: padding-box, border-box;
        }

        &:active {
            background-image: linear-gradient(white, white), linear-gradient(274deg, #6A57E3, #C26DEA);
            background-origin: border-box;
            background-clip: padding-box, border-box;
        }

        &:focus {
            border: 2px solid  #6A57E3;
        }
    }

    &__CodeNotReceived {
        color: rgba(0, 0, 0, 0.50);
        font-weight: 500;

        &__SendCodeAgain {
            background: linear-gradient(274deg, #6A57E3, #C26DEA);;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            cursor: pointer;
        }
    }
    
}