.JobDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 20px;
    padding: 20px;
    border-bottom: 2px solid #F1F1F1;
    cursor: pointer;

    &__FirstRow {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__PosterInfo {
            display: flex;
            align-items: center;
            gap: 15px;

            &__UserImage {
                display: flex;
                width: 62px;
                height: 62px;
                justify-content: center;
                align-items: center;

                img {
                    border-radius: 50%;
                    height: 51px;
                    width: 51px;
                }
            }

            &__Details {

                &__HiringType {
                    display: flex;
                    opacity: 0.6;

                    &__Verified {
                        padding-left: 10px;
                    }
                }
                &__JobTitle {
                    font-weight: 500;
                    font-size: 24px;
                }
            }
        }

        &__AdditionInfo {
            display: flex;
            gap: 8px;
        }
    }

    &__SecondRow {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        &__Tag {
            padding: 10px 15px;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #DCDCDC;
        }
    }

    &__ThirdRow {
        display: flex;
        gap: 10px;

        &__Tile {
            padding: 10px 20px;
            border-radius: 60px;
            background: #FAFAFA;
        }
    }
}