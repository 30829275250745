.Profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    gap: 30px;

    &__Header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__Title {
            font-size: 40px;
            font-weight: 700;
        }

        &__Actions {
            display: flex;
            align-items: center;
            gap: 35px;
        }
    }

    &__Body {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__EditScreen {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        &__ProfileCard {
            width: 35%;
        }

        &__JobSection {
            width: 60%;
        }
    }
}